<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <!-- <this-header /> -->
    <b-overlay
      :show="showOver"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <p id="cancel-label">กรุณารอสักครู่...</p>
        </div>
      </template>
      <div class="anan-set-nav-conttainer2 mt-150">
        <b-button
          class="btn btn-gradient-primary mt-1 mb-1 mr-50"
          @click="$router.go(-1)"
        >
          <i class="fas fa-arrow-left mr-25" /> {{ $t("key-126") }}
        </b-button>
        <validation-observer ref="simpleRules">
          <!-- <button
            class="btn btn-gradient-primary mb-1"
            :disabled="isDisabled"
            @click="Submit()"
          >
            {{ $t("saveData") }}
          </button> -->

          <!-- ที่อยู่ผู้รับสินค้า -->
          <b-row class="font-weight-bolder">
            <b-col lg="6">
              <div class="card boxshadow">
                <div class="header-cardx p-2">
                  <h4
                    class="mb-0 font-weight-bolder text-primary"
                    style="text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1)"
                  >
                    {{ $t("recipientAddress") }}
                  </h4>
                </div>

                <div class="p-2 ">
                  <b-row>
                    <!-- สถานะ -->
                    <b-col md="10 ">
                      <b-form-group
                        label-for="h-delivery"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                        
                      >
                        <template v-slot:label>
                          {{ $t("status") }}
                        </template>
                        <div class="d-flex align-items-center mt-50">
                          <div
                            v-if="
                              items.payNow === 2 &&
                              items.order_out === 1
                            "
                            class="w-50 badge badge-light-danger col-md-3"
                          >
                            {{ $t("awaitingPickup") }}
                          </div>
                          <div
                            v-if="
                              items.payNow === 2 &&
                              items.order_out === 2
                            "
                            class="w-50 badge badge-light-success col-md-3"
                          >
                            {{ $t("receivedItem") }}
                          </div>
                          <div
                            v-if="
                              items.payNow === 2 &&
                              items.order_thaiout === 1
                            "
                            class="w-50 badge badge-light-primary col-md-3"
                          >
                            {{ $t("awaitingDeliveryTH") }}
                          </div>
                          <div
                            v-if="
                              items.payNow === 2 &&
                              items.order_thaiout === 2
                            "
                            class="w-50 badge badge-light-success col-md-3"
                          >
                            {{ $t("delivered") }}
                          </div>
                          <div
                            v-if="items.payNow === 3"
                            class="w-50 badge badge-light-secondary col-md-3"
                          >
                            {{ $t("cancelled") }}
                          </div>
                          <p
                            v-if="items.payNow === 3"
                            style="color: red"
                          >
                            *{{ $t("canceledBy") }} {{ items.by_Cancel }}
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>

                    <!-- หมายเลขรายการ -->
                    <b-col md="10">
                      <b-form-group
                        label-for="h-itemnumber"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t("orderNumber") }}
                        </template>

                        <b-form-input
                          class="h-itemnumber"
                          v-model="itemnumber"
                          label="itemnumber"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <!-- ชื่อ-สกุล -->
                    <b-col md="10 ">
                      <b-form-group
                        label-for="h-getfullname"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t("key-77") }}
                        </template>
                        <b-form-input
                          class="h-getfullname"
                          v-model="fullname"
                          label="fullname"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <!-- ที่อยู่ -->

                    <b-col md="10">
                      <b-form-group
                        label-for="h-address"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t("address") }}
                        </template>

                        <b-form-input
                          class="h-address"
                          v-model="address"
                          label="address"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <!-- จังหวัด -->

                    <b-col md="10">
                      <b-form-group
                        label-for="h-province-name"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                        :label="$t('province')"
                      >
                        <b-form-input
                          class="h-province-name"
                          v-model="Province"
                          label="province"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <!-- อำเภอ -->

                    <b-col md="10">
                      <b-form-group
                        label-for="h-district"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                        :label="$t('key-102')"
                      >
                        <b-form-input
                          class="h-district"
                          v-model="district"
                          label="district"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <!--ตำบล -->

                    <b-col md="10">
                      <b-form-group
                        label-for="h-subdistrict"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                        :label="$t('key-103')"
                      >
                        <b-form-input
                          class="h-subdistrict"
                          v-model="subdistrict"
                          label="subdistrict"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <!-- รหัสไปรษณึย์ -->

                    <b-col md="10">
                      <b-form-group
                        label-for="h-parcel-code"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t("postalCode") }}
                        </template>
                        <b-form-input
                          class="h-parcel-code"
                          v-model="Zipcode"
                          label="postalcode"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <!-- ที่อยู่ผู้ส่ง -->

                    <b-col md="10">
                      <b-form-group
                        label-for="h-delivery"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t("senderAddress") }}
                        </template>

                        <b-form-input
                          class="h-delivery"
                          v-model="Delivery"
                          label="pro_name"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </div>

              <!-- รายการที่เกี่ยวข้อง -->

              <div class="card boxshadow">
                <div class="header-cardx p-2">
                  <div class="d-flex align-items-center justify-content-start">
                    <h4
                      class="font-weight-bolder text-primary"
                      style="text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1)"
                    >
                      {{ $t("relatedAmountList") }}
                    </h4>
                  </div>
                  <hr />
                  <table class="styled-table">
                    <thead>
                      <tr>
                        <th scope="col">
                          {{ $t("transactionDate") }}
                        </th>
                        <th scope="col">
                          {{ $t("details") }}
                        </th>
                        <th scope="col">
                          {{ $t("quantity") }}
                        </th>
                      </tr>
                    </thead>

                    <thead>
                      <tr
                        v-for="(arritem, index) in showbalance"
                        :key="index"
                        style="background: #fff; color: black"
                      >
                        <th
                          class="text-center"
                          scope="col"
                          style="background: #fff; color: black"
                        >
                          {{ time(arritem.created_at) }}
                        </th>
                        <th
                          class="text-center"
                          scope="col"
                          style="background: #fff; color: black"
                        >
                         {{ arritem.detail }}
                        </th>
                        <th
                          class="text-center"
                          scope="col"
                          style="background: #fff; color: black"
                        >
                          <span :style="getAmountStyle(arritem.type)">
                        {{ getAmountPrefix(arritem.type) }} {{ Commas(arritem.amount.toFixed(2)) }} ฿
                      </span>
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </b-col>

            <!-- เงื่อนไขและข้อตกลง -->

            <b-col md="6">
              <div class="card boxshadow">
                <div class="header-cardx p-2">
                  <h4
                    class="font-weight-bolder mb-0 text-primary"
                    style="text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1)"
                  >
                    {{ $t("termsAndConditions") }}
                  </h4>
                </div>
                <!-- <div class="p-2">
                <b-form-checkbox
                  v-model="payNow"
                  name="some-radio9"
                  class="custom-control-primary"
                  @input="checkreceipt(payNow)"
                >
                  <h5>จ่ายทันที</h5>

                </b-form-checkbox>
              </div> -->
                <div class="p-2">
                  <b-col md="12">
                    <div class="w-50">
                      <p class="mb-0 font-weight-bolder text-left mr-2">
                        {{ $t("receiveInstructions") }}
                      </p>
                    </div>
                  </b-col>
                  <b-col md="12">
                    <div>
                      <p class="mb-0 font-weight-bolder text-left mr-2">
                        {{ $t("receiveInstructions1") }}
                      </p>
                    </div>
                  </b-col>
                  <b-col md="12">
                    <div>
                      <p class="mb-0 font-weight-bolder text-left mr-2">
                        {{ $t("receiveInstructions2") }}
                      </p>
                    </div>
                  </b-col>
                  <b-col md="12">
                    <div>
                      <p class="mb-0 font-weight-bolder text-left mr-2">
                        {{ $t("receiveInstructions3") }}
                      </p>
                    </div>
                  </b-col>
                  <b-col md="12">
                    <div>
                      <p class="mb-0 font-weight-bolder text-left mr-2">
                        {{ $t("receiveInstructions4") }}
                      </p>
                    </div>
                  </b-col>
                  <b-col md="12">
                    <div>
                      <p class="mb-0 font-weight-bolder text-left mr-2">
                        {{ $t("receiveInstructions5") }}
                      </p>
                    </div>
                  </b-col>
                  <b-col md="12" class="mt-2 mb-2">
                    <b-form-checkbox
                      v-model="payNow"
                      name="some-radio9"
                      :value="1"
                      class="custom-control-primary"
                      disabled
                    >
                      {{ UserData.username }} {{ $t("termsAgreement") }}
                    </b-form-checkbox>
                  </b-col>
                </div>
              </div>

              <!-- การส่งออกในไทย -->

              <validation-observer ref="simpleRules">
                <div>
                  <div class="card p-2 boxshadow">
                    <h4
                      class="font-weight-bolder text-primary"
                      style="text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1)"
                    >
                      {{ $t("exportInthai") }}
                    </h4>
                    <hr />
                    <div>
                      <b-row>
                        <b-col md="12">
                          <b-form-group
                            label-for="h-postnumber_th"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t("exportTrackingNumber") }}
                            </template>
                            <b-form-input
                              class="h-postnumber_th"
                              v-model="postnumber_th"
                              label="postnumber_th"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                          label-for="h-Shipping_costth"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t("shippingcosts") }}
                            </template>
                            <b-form-input
                            class="h-Shipping_costth"
                              v-model="Shipping_costth"
                              label="Shipping_costth"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                             label-for="h-remark_th"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t("remarks") }}
                            </template>
                            <b-form-textarea
                            class="h-remark_th"
                              v-model="remark_th"
                              rows="2"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                          
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t("transactionEvidence") }}
                            </template>

                            <img
                              v-if="fileimg_th"
                              :src="GetImg('paymentfile', fileimg_th)"
                              accept=".jpg, .png, .jpeg"
                              alt="รูปภาพ"
                              class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                              @click="showPicture(fileimg_th)"
                            />
                            <vue-image-lightbox-carousel
                              ref="lightbox"
                              :show="showLightbox"
                              :images="images"
                              :show-caption="false"
                              @close="closeBox()"
                            />
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-example-datepicker"
                            label-cols="4"
                            label-cols-lg="4"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t("exportDate") }}
                            </template>

                            <b-form-datepicker
                              id="h-example-datepicker"
                              v-model="out_thaiwarehouse"
                              locale="th"
                              disabled
                            ></b-form-datepicker>

                            <!-- <b-form-datepicker
                              v-model="items.out_thaiwarehouse"
                              locale="th"
                            /> -->
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </validation-observer>
            </b-col>
          </b-row>
        </validation-observer>

        <!-- รายการรหัสพัสดุ -->

        <b-row>
          <b-col lg="12">
        <div class="card boxshadow">
          <div class="header-cardx p-2">
            <h4 class="mb-0 font-weight-bolder text-primary" style="text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1)">
              {{ $t('parcelCodeList') }}
            </h4>
          </div>

          <div class="table-container pr-2 pl-2">
            <table
              class="styled-table"
            >
              <thead>
                <tr>
                  <th>
                    {{ $t('lotSequence') }}
                  </th>

                  <th>
                    {{ $t('parcelCode') }}
                  </th>

                  <th>
                    {{ $t('orderList') }}
                  </th>

                  <th>
                    {{ $t('arrivedThailand') }}
                  </th>

                  <th>
                    {{ $t('quantity') }}
                  </th>

                  <th>
                    {{ $t('weight') }}
                  </th>

                  <th>
                    {{ $t('width') }}
                  </th>

                  <th>
                    {{ $t('height') }}
                  </th>

                  <th>
                    {{ $t('length') }}
                  </th>

                  <th>
                    {{ $t('cubic') }}
                  </th>
                  <th>
                    {{ $t('arrange') }}
                  </th>

                  <th>
                    {{ $t('type') }}
                  </th>

                  <th>
                    {{ $t('serviceFee') }}
                  </th>

                  <th>
                    {{ $t('pricePerKilo') }}
                  </th>

                  <th>
                    {{ $t('pricePerCubic') }}
                  </th>
                </tr>
              </thead>
              <thead class="text-center">
                <tr style="background: #fff; color: black;">
                  <th
                    class="text-center"
                    scope="col"
                    style="background: #fff; color: black;"
                  >
                    <b />
                  </th>
                  <th
                    class="text-center"
                    scope="col"
                    style="background: #fff; color: black;"
                  >
                    <b />
                  </th>
                  <th
                    class="text-center"
                    scope="col"
                    style="background: #fff; color: black;"
                  >
                    <b />
                  </th>
                  <th
                    class="text-center"
                    scope="col"
                    style="background: #fff; color: black;"
                  >
                    <b>{{ $t('total') }}</b>
                  </th>
                  <th
                    class="text-center"
                    scope="col"
                    style="background: #fff; color: black;"
                  >
                    <b>{{ Commas(postnumber.reduce((total,item) => total + item.product_amount,0)) }}</b>
                  </th>
                  <th
                    scope="col"
                    style="background: #fff; color: black;"
                  >
                    <b>{{ Commas(postnumber.reduce((total,item) => total + (item.weight*item.product_amount),0)) }}</b>
                  </th>
                  <th
                    scope="col"
                    style="background: #fff; color: black;"
                  >
                    <b>{{ Commas(postnumber.reduce((total,item) => total + item.width,0)) }}</b>
                  </th>
                  <th
                    scope="col"
                    style="background: #fff; color: black;"
                  >
                    <b>{{ Commas(postnumber.reduce((total,item) => total + item.height,0)) }}</b>
                  </th>
                  <th
                    scope="col"
                    style="background: #fff; color: black;"
                  >
                    <b>{{ Commas(postnumber.reduce((total,item) => total + item.long,0)) }}</b>
                  </th>
                  <th
                    scope="col"
                    style="background: #fff; color: black;"
                  >
                    <b>{{ CommasCue(postnumber.reduce((total,item) => total + (item.charge_cue*item.product_amount),0)) }}</b>
                  </th>
                  <th
                    scope="col"
                    style="background: #fff"
                  />
                  <th
                    class="text-center"
                    style="background: #fff; color: black;"
                  >
                    <b />
                  </th>
                  <th
                    class="text-center"
                    style="background: #fff; color: black;"
                  >
                    {{ sumall = Commas(postnumber.reduce((total,item) => total + (item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee),0)) }}
                    <b />
                  </th>
                  <th
                    class="text-center"
                    style="background: #fff; color: black;"
                  >
                    {{ Commas(postnumber.reduce((total,item) => total + (item.charge_per_kilo === item.charge_per_save && item.discount ? item.amount_discount: 0) + (!item.discount && item.charge_per_kilo === item.charge_per_save ? item.charge_per_kilo : 0),0)) }}
                    <b />
                  </th>
                  <th
                    class="text-center"
                    style="background: #fff; color: black;"
                  >
                    {{ Commas(postnumber.reduce((total,item) => total + (item.charge_per_cue === item.charge_per_save && item.discount ? item.amount_discount: 0) + (!item.discount && item.charge_per_cue === item.charge_per_save ? item.charge_per_cue : 0),0)) }}
                    <b />
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(item,index) in postnumber"
                  :key="index"
                >
                  <td style="width: 150px">
                    <i
                      v-if="item.transportType === 1"
                      class="fas fa-truck-moving"
                    />
                    <i
                      v-if="item.transportType === 2"
                      class="fas fa-ship"
                    />

                    {{ item.lot_name }} / {{ item.lot_order }}

                  </td>
                  <td>{{ item.postnumber }}</td>
                  <td>
                    <router-link :to="{name : 'admin-order-detail', params: {id: item.order_id}}" target="_blank" v-if="item.order_id">
                        <span>{{ item.order_text ? `ORD-${item.order_text}`: '-' }}</span>
                      </router-link>
                      <span v-else>-</span>
                  </td>
                  <td>{{ item.out_thaiwarehouse }}</td>
                  <td>{{ Commas(item.product_amount) }}</td>
                  <td>{{ Commas(item.weight*item.product_amount) }}</td>
                  <td>{{ Commas(item.width) }}</td>
                  <td>{{ Commas(item.height) }}</td>
                  <td>{{ Commas(item.long) }}</td>
                  <td>{{ CommasCue(item.charge_cue*item.product_amount) }}</td>
                  <td>{{ item.collocate_wh_name ? item.collocate_wh_name: '-' }}</td>
                  <td style="width: 50px">
                    {{ item.product_type ? item.product_type.pro_initial: '' }}
                  </td>
                  <td>{{ Commas(item.product_inspection_fee + item.re_wrap_fee + item.shipping_fee + item.other + item.packaging_fee) }}</td>
                  <td>
                    <span v-if="item.discount && item.charge_per_kilo === item.charge_per_save">
                      {{ Commas(item.charge_per_kilo) }}<span class="text-danger">-{{ Commas(item.discount) }}% = {{ Commas(item.amount_discount) }}
                      </span>
                    </span>
                    <span v-else>{{ item.charge_per_kilo === item.charge_per_save ? Commas(item.charge_per_save): '-' }}</span>
                    <!-- {{ item.charge_per_kilo === item.charge_per_save ? Commas(item.charge_per_save): '-' }} -->
                  </td>
                  <td>
                    <span v-if="item.discount && item.charge_per_cue === item.charge_per_save">
                      {{ Commas(item.charge_per_cue) }}<span class="text-danger">-{{ Commas(item.discount) }}% = {{ Commas(item.amount_discount) }}
                      </span>
                    </span>
                    <span v-else>{{ item.charge_per_cue === item.charge_per_save ? Commas(item.charge_per_save): '-' }}</span>
                    <!-- {{ item.charge_per_cue === item.charge_per_save ? Commas(item.charge_per_save): '-' }} -->

                  </td>
                </tr>
              </tbody>

              <tfoot>
                <tr>
                  <td
                    colspan="12"
                    class="text-right font-weight-bolder"
                  >
                    {{ $t('total') }}
                  </td>

                  <td
                    colspan="3"
                    class="text-right"
                  >
                    <b
                      v-if="items.coupon"
                    >
                      <b style="text-decoration: line-through; color: red;">{{ Commas(postnumber.reduce((total,item) => total + (item.charge_per_save === item.charge_per_cue && item.discount ? item.amount_discount: 0)+ (item.charge_per_save === item.charge_per_kilo && item.discount ? item.amount_discount: 0)+
                        (!item.discount ? item.charge_per_save: 0) + item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee ,0)) }} ฿</b>
                      {{ Commas((postnumber.reduce((total,item) => total + (item.charge_per_save === item.charge_per_cue && item.discount ? item.amount_discount: 0)+ (item.charge_per_save === item.charge_per_kilo && item.discount ? item.amount_discount: 0)+
                        (!item.discount ? item.charge_per_save: 0) + item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee ,0)- items.coupon.discount_coupon)) }} ฿

                      <!-- <b style="text-decoration: line-through; color: red;">{{ Commas(postnumber.reduce((total,item) => total + item.charge_per_save +item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee,0)) }} ฿ </b>
                      {{ Commas((postnumber.reduce((total,item) => total + item.charge_per_save + item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee,0)- items.coupon.discount_coupon)) }} ฿ -->

                      <!--  - arritem.coupon.discount -->
                    </b>
                    <b
                      v-else
                    >
                      {{ Commas(postnumber.reduce((total,item) => total + (item.charge_per_save === item.charge_per_cue && item.discount ? item.amount_discount: 0)+ (item.charge_per_save === item.charge_per_kilo && item.discount ? item.amount_discount: 0)+
                        (!item.discount ? item.charge_per_save: 0) + item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee ,0)) }} ฿
                    <!-- {{ Commas(postnumber.reduce((total,item) => total + item.charge_per_save + item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee,0)) }} ฿ -->
                    </b>
                    <!-- {{ postnumber.reduce((total,item) => total + item.charge_per_save,0) + sumall }} ฿ -->
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="12"
                    class="text-right font-weight-bolder"
                  >
                    {{ $t('discountCoupon') }}
                  </td>

                  <td
                    colspan="3"
                    class="text-right"
                  >
                    <b
                      v-if="items.coupon"

                      style="color: red; "
                    >{{ Commas(items.coupon.discount_coupon) }} ฿</b>
                    <b
                      v-else
                    >0 ฿ </b>
                  </td>
                </tr>

                <tr>
                  <td
                    colspan="12"
                    class="text-right font-weight-bolder"
                  >
                    {{ $t('thaiWarehouseFee') }}
                  </td>

                  <td
                    colspan="3"
                    class="text-right"
                  >
                    {{ Commas(items.Service_fee) }} ฿
                  </td>
                </tr>

                <tr>
                  <td
                    colspan="12"
                    class="text-right font-weight-bolder"
                  >
                    <small class="text-danger">{{ $t('estimatedCostNote') }}</small>   {{ $t('domesticDeliveryFee') }}
                  </td>

                  <td
                    colspan="3"
                    class="text-right"
                  >

                    <b
                      v-if="items.promotion"
                    >
                      <b
                        style="text-decoration: line-through; color: red;"
                      >{{ Commas(items.promotion.old_delivery) }} ฿</b>
                      <b> {{ Commas(items.promotion.discount_pro) }} ฿</b>
                    </b>

                    <b
                      v-else
                    >{{ Commas(items.Shipping_cost) }} ฿ </b>
                    <!-- <span>
                      55 ฿
                    </span> -->
                  </td>
                </tr>

                <tr>
                  <td
                    colspan="12"
                    class="text-right font-weight-bolder"
                  >
                    {{ $t('netAmount') }}
                  </td>

                  <td
                    colspan="3"
                    class="text-right h3"
                  >
                    {{ Commas(items.payment) }} ฿
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </b-col>
          <div class="d-flex align-items-center justify-content-start p-2" />
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormRadio,
  BFormTextarea,
  BTable,
  BFormFile,
  BOverlay,
  BButton,
   BInputGroup,
   BInputGroupAppend,
  BFormDatepicker,
} from "bootstrap-vue";
import moment from "moment-timezone";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import vSelect from "vue-select";
import VueImageLightboxCarousel from "vue-image-lightbox-carousel";
export default {
  components: {
    BButton,
    BFormDatepicker,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormRadio,
    // ThisHeader,
    BFormFile,
    BTable,
    vSelect,
    BFormTextarea,
    BOverlay,
    VueImageLightboxCarousel,
     BInputGroup,
     BInputGroupAppend,
  },
  data() {
    return {
      itemnumber: null,
      items: null,
      showpost: [],
      showbalance: [],
      amount: null,
      export_date: "",
      required,
      email,
      UserData: JSON.parse(localStorage.getItem("userData")),
      images: [],
      showLightbox: false,
      checkProduct: false,
      customerCode: null,
      parcelCode: null,
      purchaseOrder: null,
      transport: 0,
      keyword: null,
      status: null,
      remark: null,
      amount: 1,
      weight: 0,
      width: 0,
      height: 0,
      long: 0,
      ThinkPerKilo: 0,
      calPriceAuto: true,
      parcelExport: null,
      file: null,
      selectedFiles: [],
      uploadedImages: [],
      imagePreviewUrls: [],
      ID: this.$route.params.id,
      // Arritem: this.$route.params.arritems,
      // Arritem: this.$route.params.arritems,
      items: [],
      getaddress: [],
      options: [
        { value: "all", text: "ทั้งหมด" },
        { value: 1, text: "เติมเงิน" },
        { value: 2, text: "ถอนเงิน" },
        { value: 3, text: "ชำระค่านำเข้า" },
        { value: 4, text: "ค่าสินค้า" },
        { value: 5, text: "ส่วนต่างค่าขนส่งในไทย" },
        { value: 6, text: "ค่าสินค้าใน MALL" },
        { value: 7, text: "ฝากจ่ายเถาเปา" },
        { value: 8, text: "ค่า Commission" },
        { value: 9, text: "เติมเงินอาลีเพย์" },
        { value: 10, text: "โอนเงินไปจีน" },
        { value: 11, text: "แจ้งไม่ได้รับสินค้า" },
        { value: 12, text: "แจ้งร้านค้าคืนเงิน" },
        { value: 13, text: "ค่าจัดส่งในไทย" },
        { value: 14, text: "แลกเงินหยวนเป็นเงินบาท" },
        { value: 15, text: "ส่งของไปจีน" },
        { value: 16, text: "แลกแต้ม" },
        // { value: 17, text: 'อื่นๆ' },
      ],
      Fieldstex: [
        { key: "set", label: "", thStyle: { width: "5%" } },
        { key: "texfullname", label: "นาม", thStyle: { width: "20%" } },
        { key: "address", label: "ที่อยู่", thStyle: { width: "20%" } },
        { key: "telephone", label: "เบอร์โทรศัพท์", thStyle: { width: "15%" } },
        {
          key: "texnumber",
          label: "เลขผู้เสียภาษี",
          thStyle: { width: "15%" },
        },
        { key: "typetex", label: "ประเภทลูกค้า", thStyle: { width: "20%" } },
        { key: "filetex", label: "เอกสาร", thStyle: { width: "20%" } },
        { key: "actions", label: "จัดการ", thStyle: { width: "10%" } },
      ],
      addrFields: [
        { key: "set", label: "", thStyle: { width: "5%" } },
        { key: "fullname", label: "ชื่อ - สกุล", thStyle: { width: "20%" } },
        { key: "tel", label: "เบอร์โทรศัพท์", thStyle: { width: "20%" } },
        { key: "province", label: "จังหวัด", thStyle: { width: "15%" } },
        { key: "postalcode", label: "รหัสไปรษณีย์", thStyle: { width: "10%" } },
        { key: "address", label: "ที่อยู่", thStyle: { width: "20%" } },
        { key: "actions", label: "จัดการ", thStyle: { width: "10%" } },
      ],
      addrFieldshistory: [
        { key: "set", label: "", thStyle: { width: "10%" } },
        {
          key: "fullname",
          label: this.$t("firstName"),
          thStyle: { width: "15%" },
        },
        {
          key: "address",
          label: this.$t("address"),
          thStyle: { width: "15%" },
        },
        { key: "tel", label: this.$t("key-81"), thStyle: { width: "10%" } },
        { key: "sender", label: this.$t("sender"), thStyle: { width: "15%" } },
        {
          key: "deliveryType",
          label: this.$t("deliveryType"),
          thStyle: { width: "20%" },
        },
        {
          key: "province",
          label: this.$t("province"),
          thStyle: { width: "10%" },
        },
        { key: "actions", label: this.$t("manage"), thStyle: { width: "5%" } },
      ],
      postalCode: null,
      fullname: null,
      phoneNum: null,
      address: null,
      Province: null,
      district: null,
      subdistrict: null,
      postal: null,
      setDefault: 0,
      selectedProvince: null,
      selectedDistrict: null,
      selectedSubDistrict: null,
      
      idistrict: [],
      iSubdistrict: [],
      Zipcode: null,
      subDistrict: [],
      receipt: false,
      delivery: 0,
      itemcoupon: [],
      Fieldscoupon: [
        { key: "setcoupons", label: "", thStyle: { width: "5%" } },
        { key: "coupons", label: "คูปอง", thStyle: { width: "25%" } },
        {
          key: "detailcoupons",
          label: "รายละเอียด",
          thStyle: { width: "70%" },
        },
      ],
      couponitem: null,
      couponcheck: null,
      Delivery: null,
      telnumber: null,
      sumsave: 0,
      sumservice: 0,
      itemsTax: null,
      itemsTaxall: null,
      itemsTaxpayer: [],
      payNow: 1,
      selectfile: null,
      namefile: null,
      idtex: null,
      typetex: 1,
      texfullname: null,
      addresstex: null,
      telephone: null,
      Taxpayer: null,
      paymentuser: [],
      sumuser: 0,
      rolelist: [],
      productType: null,
      transports: 0,
      itempro: [],
      bydelivery: null,
      itemshipping: [],
      sumshipping: 0,
      deliverymoney: 0,
      deliverypay: 0,
      proItems: [],
      sumitem: 0,
      liveryname: null, // ชื่อผู้ส่ง
      deliveryaddress: null, // ที่อยู่ผู้ส่ง
      deliverykeyword: null, // เบอร์ติดต่อผู้ส่ง
      getfullname: null,
      addressget: null,
      general: 0,
      showOver: false,
      isDisabled: false,
      WarehousePrice: null,
      gethistory: [],
      history_id: null,
      Arritem: [],
      StatusShow: null,
      fileimg_th: null,
      fileimg: null,
      out_thaiwarehouse: null,
      filename: null,
      postnumber_th: null,
      Shipping_costth: null,
      created_at: null,
      postnumber: [],
      totalRows: 0,
      total: 0,
      sumall: 0,
      remark_th:null,
    };
  },
  computed: {
    optiondelivery() {
      return [
        { value: 0, label: this.$t("key-310") },
        { value: 1, label: "ANAN CARGO" },
        { value: 2, label: this.$t("key-311") },
        { value: 3, label: this.$t("key-312") },
      ];
    },
  },
  mounted() {
    this.ShowPayment();
    this.ShowBalance();
    // this.ShowBalance();
    // this.ShowPost();
  },
  methods: {
    time(data) {
      return moment(data).tz("Asia/Bangkok").format("YYYY-MM-DD HH:mm:ss");
    },

    async ShowPayment() {
      this.showOver = true
      try {
        const params = {
          ID: this.ID,
        };
        const { data: res } = await this.$http.get("/Postlay/showPayment", {
          params,
        });
        
        this.items = res;
        this.address = res.address.address;
        this.fullname = res.address.fullname;
        this.itemnumber = res.itemnumber_text;
        this.Province = res.address.province_name;
        this.district = res.address.district;
        this.subdistrict = res.address.subdistrict;
        this.Zipcode = res.address.postalcode;
        // this.Delivery = res.typedelivery.pro_name
        this.Delivery = res.delivery.name;
        this.StatusShow = res.statusbill;
        this.fileimg_th = res.fileimg_th;
        this.out_thaiwarehouse = res.out_thaiwarehouse;
        this.postnumber_th = res.postnumber_th;
        this.Shipping_costth = res.Shipping_costth;
        this.ShowPost(res)
        
        this.showOver = false
      } catch (err) {
        console.log(err);
      }
    },
    ShowBalance(){
      this.showOver = true
      const params ={
        ID: this.ID
      }
      this.$http.get("/Postlay/showBalance", { params })
      .then(response =>{
        this.showbalance = response.data
        // console.log(this.showbalance)
        this.showOver = false
      })
      .catch(err => console.log(err))
    },

     ShowPost(item){
      this.showOver = true
      // console.log('item', item)
      const params = {    
        _id : item.postnumber_id,
      };
    
      this.$http.get('/Postlay/showPost', {params})
      .then(response =>{
        this.postnumber = response.data
        // console.log(response)
        this.showOver = false
      })
      .catch(err => console.log(err))
     },

    getAmountStyle(amount) {
      if (amount) {
        if (amount && [3, 4, 5, 6, 7, 13, 15, 16].includes(amount)) {
          return { color: 'green' }
        }
        if (amount && [8, 11, 12, 17].includes(amount)) {
          return { color: 'red' }
        }
      }
      return { color: 'orange' }
    },
    getAmountPrefix(value) {
      if (value) {
        if (value && [3, 4, 5, 6, 7, 13, 15, 16].includes(value)) {
          return '+'
        }
        if (value && [8, 11, 12, 17].includes(value)) {
          return '-'
        }
      }
      return ''
    },

    GetImg(pathfile, filename) {
      const matches = filename.match(
        /^data:image\/([A-Za-z-+\/]+);base64,(.+)$/
      );
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`;
      }
      return filename;
    },

    showPicture(picture) {
      this.showLightbox = true;
      const img = { path: this.GetImg("paymentfile", picture) };
      this.images.push(img);
    },

    closeBox() {
      this.showLightbox = false
      this.images = []
    },

    checkfile(event) {
      this.fileimg = event.target.files;
      this.fileimg_th = null;
      const reader = new FileReader();
      const filepath = this.fileimg[0];
      reader.onload = (e) => {
        // console.log(e.target.result)
        this.fileimg_th = e.target.result;
        // e.target.result
        // console.log(this.namemap)
      };
      reader.readAsDataURL(filepath);
    },

    handleFileChange(event) {
      const file = event.target.files[0];
      this.imgBackground = file;
      this.previewImage(file);
    },
    previewImage(file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreviewUrl = reader.result;
      };
      reader.readAsDataURL(file);
    },
    Success(mes) {
      this.$swal({
        icon: "success",
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    SwalError(mes) {
      this.$swal({
        icon: "error",
        title: `<h3 style="color: #141414">${this.$t("key-298")}</h3>`,
        text: mes,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    showAdd() {
      this.$bvModal.show("add-addr");
    },
    showModal() {
      this.name = null;
      this.phone = null;
      this.address = null;
      this.province = null;
      this.district = null;
      this.subdistrict = null;
      this.postalcode = null;
      this.$bvModal.show("modal-1");
    },
  },
};
</script>

<style>
.btn-select {
  margin-top: 5px;
  padding: 1.5rem 2.5rem;
  border: 1px dashed #979797;
  border-radius: 14px;
  background-color: #c1ecfd;
}
.gradient-card {
  background: linear-gradient(
    to bottom,
    #f0f9ff,
    #e5f5ff
  ); /* ไล่สีจากฟ้าจางไปฟ้าเข้ม */
  border-radius: 8px; /* เพิ่มขอบโค้งถ้าต้องการ */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* เพิ่มเงา */
}
.boxshadow {
  box-shadow: 0px 0px 10px 3px rgba(2, 55, 160, 0.1);
}




</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
